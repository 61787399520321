import React from 'react';
import {
  AccessibilityButton,
  BasketCount,
  CancelOrderButton,
  PrimaryButton,
} from '@fingermarkglobal/cringer.components';
import CartIconImage from '../../../assets/images/cart-icon.svg';

const Footer = ({
  total = null,
  nextText = 'Next',
  nextAction = null,
  allowNextAction = true,
  className = '',
  count = 0,
  spacer = null,
  showBasketCount = true,
  enableCancelOrder = false,
  cancelOrderText = 'Cancel',
  onBasketClick = () => {},
  showAccessibilityButton = false,
} = {}) => {
  return (
    <div
      data-test="footer"
      className={`flex justify-between items-center px-8 py-16 bg-accessory space-x-8 ${className}`}
    >
      {showAccessibilityButton && <AccessibilityButton className="p-2 w-24 h-24 shadow-lg" />}
      <div data-test="bottom" className="flex items-center justify-end">
        {enableCancelOrder && (
          <CancelOrderButton className="ml-8">{cancelOrderText}</CancelOrderButton>
        )}
        {spacer}
        {!!nextAction && (
          <PrimaryButton className="ml-4" disabled={!allowNextAction} onClick={nextAction}>
            {nextText}
          </PrimaryButton>
        )}
        {showBasketCount && (
          <BasketCount
            className="ml-8"
            count={count}
            onClick={onBasketClick}
            Icon={CartIconImage}
          />
        )}
        <div className="font-bold text-4xl ml-8">{total}</div>
      </div>
    </div>
  );
};

export { Footer };
