import Dinero from 'dinero.js';

const currencyFormat = process.env.POI_APP_CURRENCY_FORMAT || '$0,0.00';

const join = (...params) => (separator = ' | ') => {
  return params.filter(param => !!param).join(separator);
};

const formatPrice = ({ price, currency } = {}) => {
  // string value will be `price not found` so return that to display
  if (typeof price === 'string') return price;

  const amount = Math.round((price || 0) * 100);
  const options = { amount };
  return Dinero(currency ? { ...options, currency } : options).toFormat(currencyFormat);
};

const round = value => Math.round(value * 100) / 100;

const formatAmountInCents = ({ amount }) => Math.round((amount * 100 + Number.EPSILON) * 100) / 100;

const signaledFormatPrice = ({ formatPrice: formatter = () => {}, price }) => {
  return `${price > 0 ? '+' : ''}${formatter({ price })}`;
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export { join, formatPrice, round, formatAmountInCents, signaledFormatPrice, capitalizeFirstLetter  };
