import React from 'react';
import { Decoration } from '../decoration';
import Logo from '../../assets/images/logo.svg';
import { Loading as GenericLoading } from '@fingermarkglobal/cringer.components';

const Loading = ({
  'data-test': dataTest = 'loading',
  title = 'Warming up the kitchen',
  message = 'Please wait',
  Image = null,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <Decoration className="absolute top-0" />
      <Logo className="w-64 h-64" />
      <GenericLoading data-test={dataTest} title={title} message={message} Image={Image} />
    </div>
  );
};

export { Loading };
