import React from 'react';
import {
  AccessibilityWrapper,
  AccessibilityButton,
  TimeoutModal,
  useTemplate,
} from '@fingermarkglobal/cringer.components';
import { Decoration } from '../../components/stateless/decoration';
import { Header } from '../../components/stateless/header';
import { Footer } from '../../components/stateless/footer';

import Wheelchair from '../../assets/images/wheelchair.svg';

const CustomDefaultTemplate = ({
  children,
  timeoutEnabled = true,
  footerEnabled = true,
  headerEnabled = true,
  accessibilityActionEnabled = true,
  templateOverride = false,
}) => {
  const {
    header,
    footer,
    modalAccessibilityClass,
    accessibilityEnabled,
    isTimeoutEnabled,
    timeoutActionOverride,
    isOnline,
    hideHeaderAndFooter,
    isCouponEnabled,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const showFooterAccessibilityButton = accessibilityActionEnabled && footerEnabled;
  const showStaticAccessibilityButton = accessibilityActionEnabled && !footerEnabled;

  const isAccessibilityButtonEnabled =
    accessibilityActionEnabled || showFooterAccessibilityButton || showStaticAccessibilityButton;
  const decorationClassNames =
    accessibilityEnabled && isAccessibilityButtonEnabled ? 'absolute top-0 h-half w-full' : '';

  return (
    <AccessibilityWrapper isAccessibilityButtonEnabled={isAccessibilityButtonEnabled}>
      <div className="w-screen flex flex-col h-full w-full">
        <Decoration
          showLogo={accessibilityEnabled && isAccessibilityButtonEnabled}
          className={decorationClassNames}
        />
        {headerEnabled && !hideHeaderAndFooter && (
          <Header {...header} isCouponEnabled={isCouponEnabled} />
        )}
        <div className="flex-grow overflow-auto">{children}</div>
        {footerEnabled && !hideHeaderAndFooter && (
          <Footer {...footer} showAccessibilityButton={showFooterAccessibilityButton} />
        )}
        {showStaticAccessibilityButton && (
          <AccessibilityButton
            AccessibilityIcon={Wheelchair}
            className="p-2 block w-24 h-24 absolute bottom-0 left-0 shadow-lg ml-8 my-16 z-40"
          />
        )}
        {isTimeoutEnabled && isOnline && (
          <TimeoutModal
            onTimeout={timeoutActionOverride}
            modalAccessibilityClass={modalAccessibilityClass}
          />
        )}
      </div>
    </AccessibilityWrapper>
  );
};

export { CustomDefaultTemplate };
