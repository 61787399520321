const resultMessage = {
  rawResponse: '',
  success: false,
  message: null,
  isFinished: false,
  started: false,
  showReceipt: false,
};

const ResponseCodes = {
  SUCCESS: '00',
  DECLINED: '05',
  INCORRECT_PIN: '55',
  TIMEOUT: '91',
};

const getMessageCategory = message => {
  return message?.MessageCategory || '';
};

const readMessage = message => {
  const responseCode = message?.RspCode || '';

  switch (responseCode) {
    case ResponseCodes.SUCCESS: {
      resultMessage.success = true;
      break;
    }
    case ResponseCodes.DECLINED:
    case ResponseCodes.INCORRECT_PIN:
    case ResponseCodes.TIMEOUT: {
      resultMessage.success = false;
      resultMessage.showReceipt = true;
      break;
    }
    default:
      break;
  }

  resultMessage.rawResponse = message;

  return resultMessage;
};

const parseMessage = buffer => {
  try {
    const toString = Buffer.from(buffer.buffer, 'hex').toString();

    // this is a check if a message comes complete or broken into pieces
    // (edge case scenario but make sure the code doesn't break)
    if (toString.charAt(0) === '' && toString.charAt(toString.length - 1) === '') {
      resultMessage.isFinished = true;
      resultMessage.rawResponse = toString.substring(1, toString.length - 1);
    } else if (toString.charAt(0) === '' && !resultMessage.started) {
      resultMessage.started = true;
      resultMessage.rawResponse += toString.substring(1);
    } else if (toString.charAt(toString.length - 1) === '' && resultMessage.started) {
      resultMessage.rawResponse += toString.slice(0, -2);
      resultMessage.isFinished = true;
    } else {
      resultMessage.rawResponse += toString;
    }

    if (resultMessage.isFinished) {
      const message = JSON.parse(resultMessage.rawResponse);
      const response = message?.SaleToPOIResponse || {};
      const category = getMessageCategory(response?.MessageHeader);

      switch (category) {
        case 'Payment': {
          return readMessage(response?.PaymentResponse);
        }
        default:
          throw new Error('Unknown message category');
      }
    }

    return resultMessage;
  } catch (error) {
    throw new Error(error);
  }
};

export { parseMessage };
